
import { contentsService, StaticContent } from "@/services/contents.service";
import { defineComponent } from "vue";
import PageHeadline from "@/common/PageHeadline.vue";

export default defineComponent({
  name: "StaticPage",
  components: { PageHeadline },
  data() {
    return {
      content: undefined as StaticContent | undefined,
    };
  },
  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      const slug = "" + this.$route.params.slug;
      if (slug) {
        contentsService.findBySlug(slug).then((content) => {
          this.content = content;
        });
      }
    },
  },
  watch: {
    $route(route) {
      this.loadData();
      // contentsService.findBySlug(route.params.slug).then(content => {
      //   this.content = content;
      // });
    },
  },
});
