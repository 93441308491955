import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from './views/Home.vue';
import StaticPage from '@/views/StaticPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/page/:slug',
    name: 'StaticPage',
    component: StaticPage,
    meta: {
      slug: 'cookie-policy',
    },
  },
  {
    path: '/our-team',
    component: () => import('./modules/our-team/OurTeam.vue'),
  },
  {
    path: '/our-services',
    component: () => import('./modules/our-services/OurServices.vue'),
  },
  {
    path: '/join-us',
    component: () => import('./modules/join-us/OpenPositionsPaginator.vue'),
  },
  {
    path: '/join-us/:slug',
    component: () => import('./modules/join-us/OpenPosition.vue'),
  },
  {
    path: '/join-us/:slug/apply',
    component: () => import('./modules/join-us/OpenPositionApply.vue'),
  },
  {
    path: '/success-stories/:slug?',
    component: () => import('./modules/success-stories/SuccessStories.vue'),
  },
  {
    path: '/contact-us',
    component: () => import('./modules/contant-us/ContactUs.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ './views/About.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top:
          (document.querySelector(to.hash) as HTMLInputElement).offsetTop -
          (document.querySelector('.header') as HTMLInputElement).offsetHeight,
        behavior: 'smooth',
      });
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes,
});

export default router;
