
import { Options, Vue } from "vue-class-component";
import AppFooter from "@/components/AppFooter.vue";
import { contentsService } from "@/services/contents.service";
import { setPageDescription, setPageTitle } from "@/common/meta.utils";
import AppHeader from "@/components/AppHeader.vue";

@Options({
  components: { AppHeader, AppFooter },
})
export default class App extends Vue {
  created() {
    setPageTitle();
    setPageDescription();
  }

  async mounted() {
    await contentsService.load();
  }
}
