import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("figure", {
    class: _normalizeClass(["image", _ctx.className])
  }, [
    (_ctx.filePath)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.filePath
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}