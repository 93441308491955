<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0004 21.6537L0.34668 11.9999L10.0004 2.34619L11.4197 3.76544L3.18493 11.9999L11.4197 20.2344L10.0004 21.6537Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-submenu-hide",
};
</script>
<style scoped lang="scss"></style>
