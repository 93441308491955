
import { defineComponent, ref, onMounted } from "vue";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import IconArrowRight from "@/icons/icon-arrow-right.vue";
import IconLinkedin from "@/icons/icon-linkedin.vue";
import { Grid } from "@splidejs/splide-extension-grid";
import IconArrowLink from "@/icons/icon-arrow-link.vue";

export default defineComponent({
  name: "ImagesSlider",
  components: {
    IconArrowLink,
    IconLinkedin,
    IconArrowRight,
    Splide,
    SplideSlide,
    SplideTrack,
  },
  props: {
    id: String,
    images: Array,
    height: Number,
    imageClass: String,
    options: Object,
    showTitle: {
      type: Boolean,
      default: false,
    },
    showDesc: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const mainSlider = ref<InstanceType<typeof Splide>>();
    const thumbSlider = ref<InstanceType<typeof Splide>>();

    onMounted(() => {
      const thumbsSplide = thumbSlider.value?.splide;

      if (thumbsSplide) {
        mainSlider.value?.sync(thumbsSplide);
      }
    });

    return {
      mainSlider,
      thumbSlider,
      extensions: { Grid },
    };
  },
  computed: {
    mainOptions() {
      return {
        ...this.options,
        pagination: false,
      };
    },
    thumbOptions() {
      return {
        perMove: 1,
        perPage: 5,
        isNavigation: true,
        pagination: false,
        arrows: false,
        focus: "center",
        gap: 2,
        drag: false,
        loop: true,
        autoWidth: true,
      };
    },
  },
  methods: {
    getImgUrl(path: string): string {
      return require("../assets/" + path);
    },
  },
});
