<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon / expand_more">
      <path
        id="Vector"
        d="M19.9996 24.5024L11.1533 15.6561L12.3329 14.4766L19.9996 22.1432L27.6662 14.4766L28.8458 15.6561L19.9996 24.5024Z"
        fill="#767E81"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "icon-expand-more",
};
</script>
<style scoped lang="scss"></style>
