import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ea12d7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "the-best__subtitle subtitle subtitle--left--mobile text-primary" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "the-best__items" }
const _hoisted_6 = { class: "container" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "accordion__col__header" }
const _hoisted_10 = { class: "accordion__col__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_expand_more = _resolveComponent("icon-expand-more")!

  return (_openBlock(), _createElementBlock("section", {
    id: "the-best",
    class: _normalizeClass(["the-best", { expanded: _ctx.expandAll }])
  }, [
    (!_ctx.hideHeadline)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("header", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.page?.overSubtitle), 1),
            _createElementVNode("h2", {
              innerHTML: _ctx.titlePipe(_ctx.page?.subtitle)
            }, null, 8, _hoisted_4)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedBullets, (row, rowIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["accordion__row", { active: _ctx.isRowActive(rowIndex) }]),
            key: rowIndex,
            onClick: ($event: any) => (_ctx.toggleActiveRow(rowIndex))
          }, [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (bullet, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["accordion__col", { active: _ctx.isColActive(bullet) }]),
                  key: index,
                  onClick: ($event: any) => (_ctx.toggleActiveCol(bullet))
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["icon", bullet?.icon])
                    }, null, 2),
                    _createElementVNode("h3", null, _toDisplayString(bullet?.title), 1),
                    _createVNode(_component_icon_expand_more)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bullet?.list, (row, rowIndex) => {
                        return (_openBlock(), _createElementBlock("li", { key: rowIndex }, _toDisplayString(row), 1))
                      }), 128))
                    ])
                  ])
                ], 10, _hoisted_8))
              }), 128))
            ])
          ], 10, _hoisted_7))
        }), 128))
      ])
    ])
  ], 2))
}