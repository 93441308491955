
import { defineComponent, onMounted, ref } from "vue";
import {
  GenericContent,
  getThumbLinks,
  paginatorService,
} from "@/services/paginator.service";
import IconArrowLink from "@/icons/icon-arrow-link.vue";
import { Splide } from "@splidejs/vue-splide";
import ImagesSlider from "@/components/ImagesSlider.vue";

export default defineComponent({
  name: "HomeOurStories",
  components: {
    ImagesSlider,
    IconArrowLink,
  },
  props: {
    itemsToShow: {
      type: Number,
      default: 6,
    },
    showMoreBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      list: undefined as GenericContent[] | undefined,
      listImages: undefined as any | undefined,
    };
  },
  setup() {
    const mainSlider = ref<InstanceType<typeof Splide>>();
    const thumbSlider = ref<InstanceType<typeof Splide>>();

    onMounted(() => {
      const thumbsSplide = thumbSlider.value?.splide;

      if (thumbsSplide) {
        mainSlider.value?.sync(thumbsSplide);
      }
    });

    const options = {
      pagination: false,
      arrows: false,
      grid: {
        rows: "auto",
        cols: 3,
        gap: {
          row: "5rem",
          col: "5rem",
        },
      },
      breakpoints: {
        1279: {
          grid: {
            rows: "auto",
            cols: 2,
          },
        },
        1023: {
          fixedWidth: 326,
          perPage: 1,
          perMove: 1,
          grid: false,
          gap: "2.5rem",
        },
      },
    };

    const thumbOptions = {
      perMove: 1,
      isNavigation: true,
      pagination: false,
      arrows: false,
      focus: "center",
      gap: 2,
      drag: false,
      loop: true,
      autoWidth: true,
    };

    return {
      mainSlider,
      thumbSlider,
      options,
      thumbOptions,
    };
  },
  mounted() {
    paginatorService.load("success-stories-collection").then((list) => {
      this.list = list.slice(0, this.itemsToShow);
      this.listImages = list
        .slice(0, this.itemsToShow)
        .filter((item) => !!item.thumb)
        .map((item) => {
          const link = getThumbLinks(item.thumb);
          if (link) {
            return {
              url: link.small,
              slug: item.slug,
              title: item.iconTitle,
              subtitle: item.subtitle,
            };
          }
        });
    });
  },

  methods: {
    goto(item: GenericContent) {
      this.$router.push("/success-stories/" + item.slug);
    },
  },
});
