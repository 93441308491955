
import { defineComponent } from "vue";
import { contentsService, StaticContent } from "@/services/contents.service";
import { titleFilter } from "@/common/title.filter";
import Thumb from "@/common/Thumb.vue";
import { Counter } from "@/common/counter.utils";

export default defineComponent({
  name: "YourNeeds",
  components: { Thumb },
  data() {
    return {
      page: undefined as StaticContent | undefined,
    };
  },
  mounted() {
    contentsService.findBySlug("page-your-needs").then((page) => {
      this.page = page as StaticContent;
    });
    new Counter(".your-needs__numbers");
  },

  methods: {
    titlePipe(title: string) {
      return titleFilter(title);
    },
  },
});
