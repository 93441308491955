export function setPageTitle(title = 'Driving your success in digital world') {
  document.title = 'Engenious' + (title ? ' - ' + title : '');
}

export function setPageDescription(description?: string) {
  if (!description) {
    description =
      'Delivering the optimal solution for your business with our skilled and driven Experts certified across leading technologies.';
  }
  const descEl = document.querySelector('meta[name="description"]');
  if (descEl) {
    descEl.setAttribute('content', description);
  }
}
