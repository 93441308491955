import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDSjE8zPRqndzba1_douIIYZHb5Ei_e7mU',
  authDomain: 'engenious-website.firebaseapp.com',
  projectId: 'engenious-website',
  storageBucket: 'engenious-website.appspot.com',
  messagingSenderId: '1054157112788',
  appId: '1:1054157112788:web:4e38a8a12a287361f44241',
  measurementId: '${config.measurementId}',
};

// firebase.initializeApp(config);
//
// export default firebase.database();

export const firebaseApp = initializeApp(firebaseConfig);
