
import { defineComponent } from "vue";
import { contentsService, StaticContent } from "@/services/contents.service";
import { titleFilter } from "@/common/title.filter";
import IconExpandMore from "@/icons/icon-expand-more.vue";

export default defineComponent({
  name: "HomeTheBest",
  components: { IconExpandMore },
  props: {
    hideHeadline: {
      type: Boolean,
      default: false,
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: undefined as StaticContent | undefined,
      activeRowIndex: -1,
      activeColIndex: -1,
    };
  },
  computed: {
    groupedBullets(): Array<Array<any>> {
      const bullets: any[] = this.page?.bullets || [];

      bullets.forEach((item) => {
        item.list = (item.description || "")
          .split("--")
          .map((el: string) => el.trim())
          .filter((el: string) => !!el);
      });

      const rows = [];

      for (let i = 0; i < bullets.length; i += 3) {
        rows.push(bullets.slice(i, i + 3));
      }

      return rows;
    },
  },
  mounted() {
    contentsService.findBySlug("page-our-services").then((page) => {
      this.page = page as StaticContent;
    });
  },
  methods: {
    titlePipe(title: string) {
      return titleFilter(title);
    },
    toggleActiveRow(rowIndex: number) {
      if (window.innerWidth < 1024) {
        return;
      }
      if (this.expandAll) {
        this.activeRowIndex = -1;
      } else if (this.activeRowIndex === rowIndex) {
        this.activeRowIndex = -1;
      } else {
        this.activeRowIndex = rowIndex;
      }
    },
    toggleActiveCol(colIndex: number) {
      if (window.innerWidth >= 1024) {
        return;
      }
      if (this.expandAll) {
        this.activeColIndex = -1;
      } else if (this.activeColIndex === colIndex) {
        this.activeColIndex = -1;
      } else {
        this.activeColIndex = colIndex;
      }
    },
    isRowActive(rowIndex: number) {
      return this.expandAll || this.activeRowIndex === rowIndex;
    },
    isColActive(colIndex: number) {
      return this.expandAll || this.activeColIndex === colIndex;
    },
  },
});
