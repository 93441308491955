
import Hero from "@/components/Hero.vue";
import YourNeeds from "@/components/YourNeeds.vue";
import Industries from "@/components/Industries.vue";
import HomeOurStories from "@/modules/success-stories/HomeOurStories.vue";
import WhatWeValue from "@/components/WhatWeValue.vue";
import LaunchYourProduct from "@/components/LaunchYourProduct.vue";
import HomeOurPeople from "@/modules/our-team/HomeOurPeople.vue";
import HomeTheBest from "@/modules/our-services/HomeTheBest.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: {
    Industries,
    HomeTheBest,
    HomeOurPeople,
    LaunchYourProduct,
    WhatWeValue,
    HomeOurStories,
    YourNeeds,
    Hero,
  },
  data() {
    return {
      showAll: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", (e) => {
      let lastKnownScrollPosition = window.scrollY;
      if (lastKnownScrollPosition > 400) {
        this.showAll = true;
      }
    });
  },
});
