import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2328f94c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "our-people has-background" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "our-people__header" }
const _hoisted_4 = { class: "text-primary subtitle subtitle--left--mobile" }
const _hoisted_5 = { class: "lead" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_images_slider = _resolveComponent("images-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.page?.overSubtitle), 1),
        _createElementVNode("h2", null, _toDisplayString(_ctx.page?.title), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.page?.lead), 1)
      ]),
      (_ctx.peopleImages)
        ? (_openBlock(), _createBlock(_component_images_slider, {
            key: 0,
            id: 'people',
            class: "people",
            "items-to-show": 6,
            images: _ctx.peopleImages,
            "show-title": true,
            "show-desc": true,
            options: _ctx.options
          }, null, 8, ["images", "options"]))
        : _createCommentVNode("", true)
    ])
  ]))
}