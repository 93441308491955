
import { defineComponent } from "vue";
import { contentsService, StaticContent } from "@/services/contents.service";
import ImagesSlider from "@/components/ImagesSlider.vue";
import { getThumbLinks, paginatorService } from "@/services/paginator.service";
import { titleFilter } from "@/common/title.filter";
import Thumb from "@/common/Thumb.vue";

export default defineComponent({
  name: "Industries",
  components: { Thumb, ImagesSlider },
  data() {
    return {
      certificates: undefined as any | undefined,
      options: {
        perPage: 5,
        perMove: 1,
        pagination: false,
        gap: "2.5rem",
        breakpoints: {
          1279: {
            fixedWidth: 223,
            perPage: 1,
            perMove: 1,
            arrows: false,
          },
        },
      },
      page: undefined as StaticContent | undefined,
    };
  },

  mounted() {
    contentsService.findBySlug("page-industries-header").then((page) => {
      this.page = page as StaticContent;
    });

    paginatorService.load("industries").then((list) => {
      this.certificates = list
        .filter((item) => !!item.thumb)
        .map((item) => {
          const link = getThumbLinks(item.thumb);
          if (link) {
            return {
              url: link.small,
              title: item.title,
            };
          }
        });
    });
  },
  methods: {
    titlePipe(title?: string) {
      return title ? titleFilter(title) : "";
    },
  },
});
