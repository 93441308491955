
import { Options, Vue } from "vue-class-component";
import IconLogo from "@/icons/icon-logo.vue";
import IconLinkedin from "@/icons/icon-linkedin.vue";

@Options({
  components: { IconLinkedin, IconLogo },
  props: {
    msg: String,
  },
})
export default class AppFooter extends Vue {
  currentYear: number | null = null;

  mounted(): void {
    let currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
  }
}
