
import { defineComponent } from "vue";
import { contentsService, StaticContent } from "@/services/contents.service";
import ImagesSlider from "@/components/ImagesSlider.vue";
import { getThumbLinks, paginatorService } from "@/services/paginator.service";

export default defineComponent({
  name: "HomeOurPeople",
  components: { ImagesSlider },
  data() {
    return {
      page: undefined as StaticContent | undefined,
      peopleImages: undefined as any | undefined,
      options: {
        perPage: 5,
        fixedWidth: "14rem",
        gap: "2.5rem",
        breakpoints: {
          1365: {
            perPage: 1,
          },
          1279: {
            arrows: false,
          },
        },
      },
    };
  },
  mounted() {
    contentsService.findBySlug("page-our-team-list").then((page) => {
      this.page = page as StaticContent;
    });
    paginatorService.load("our-team-collection").then((list) => {
      this.peopleImages = list
        .filter((item) => !!item.thumb)
        .map((item) => {
          const link = getThumbLinks(item.thumb);
          if (link) {
            return {
              url: link.small,
              title: item.name,
              subtitle: item.surname,
              desc: item.role,
              linkedin: item.linkedin,
            };
          }
        });
    });
  },
});
