import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-717e00a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "industry" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "industry__header" }
const _hoisted_4 = { class: "subtitle subtitle--left text-primary" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "lead" }
const _hoisted_7 = { class: "industry__image" }
const _hoisted_8 = { class: "industry__expertise container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thumb = _resolveComponent("thumb")!
  const _component_images_slider = _resolveComponent("images-slider")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.page?.overSubtitle), 1),
        _createElementVNode("h2", {
          class: "industry__title highlighted highlighted--primary",
          innerHTML: _ctx.titlePipe(_ctx.page?.subtitle)
        }, null, 8, _hoisted_5),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.page?.subtitleDescription), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_thumb, {
          file: _ctx.page?.additionalThumb1
        }, null, 8, ["file"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.page?.title), 1),
      _createVNode(_component_images_slider, {
        class: "certificates",
        images: _ctx.certificates,
        options: _ctx.options,
        "show-title": true,
        id: 'certificates'
      }, null, 8, ["images", "options"])
    ])
  ]))
}