
import { defineComponent } from "vue";
import { contentsService, StaticContent } from "@/services/contents.service";
import { getThumbLinks } from "@/services/paginator.service";
import { titleFilter } from "@/common/title.filter";
import Thumb from "@/common/Thumb.vue";
import IconArrowLink from "@/icons/icon-arrow-link.vue";

export default defineComponent({
  name: "WhatWeValue",
  components: { IconArrowLink, Thumb },
  data() {
    return {
      page: undefined as StaticContent | undefined,
      image1: undefined as string | undefined,
      image2: undefined as string | undefined,
      image3: undefined as string | undefined,
    };
  },
  mounted() {
    contentsService.findBySlug("page-what-we-value").then((page) => {
      this.page = page as StaticContent;
    });
  },
  methods: {
    titlePipe(title: string) {
      return titleFilter(title);
    },
  },
});
