import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5c89572"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "your-needs" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "your-needs__image" }
const _hoisted_4 = { class: "your-needs__header" }
const _hoisted_5 = { class: "subtitle subtitle--left text-primary" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "lead" }
const _hoisted_8 = { class: "your-needs__numbers" }
const _hoisted_9 = { class: "number" }
const _hoisted_10 = { class: "number__title" }
const _hoisted_11 = ["data-counter"]
const _hoisted_12 = {
  key: 0,
  class: "separator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thumb = _resolveComponent("thumb")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_thumb, {
          file: _ctx.page?.additionalThumb1
        }, null, 8, ["file"])
      ]),
      _createElementVNode("header", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.page?.overSubtitle), 1),
        _createElementVNode("h2", {
          class: "your-needs__title highlighted highlighted--primary",
          innerHTML: _ctx.titlePipe(_ctx.page?.title)
        }, null, 8, _hoisted_6),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.page?.lead), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.page?.bullets, (row, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", {
                  "data-counter": row?.icon.slice(0, -1)
                }, "0", 8, _hoisted_11),
                _createTextVNode(_toDisplayString(row?.icon.slice(-1)), 1)
              ]),
              _createElementVNode("p", null, _toDisplayString(row?.title), 1)
            ]),
            (i < _ctx.page.bullets.length - 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ])
  ]))
}