<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.01593 21.6537L6.59668 20.2344L14.8312 11.9999L6.59668 3.76544L8.01593 2.34619L17.6697 11.9999L8.01593 21.6537Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-submenu-show",
};
</script>
<style scoped lang="scss"></style>
