<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.55375 19.5L4.5 18.4463L15.9462 7H9V5.5H18.5V15H17V8.05375L5.55375 19.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-arrow-link",
};
</script>
<style scoped lang="scss"></style>
